import { Link } from "react-router-dom";
import { useUser } from "../contexts/user";
import React, { useEffect, useState } from "react";
import { getTeam, getTeamIssues, getTeamAssignments, getRecentTeamIssues } from "../api/teams";
import { getTeamNotifications } from "../api/notifications";
import { deleteAssignment } from "../api/assignments";
import { useParams } from "react-router-dom";
import { PlusCircleIcon, MinusCircleIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import moment from "moment-timezone";
const TIMEZONE = process.env.REACT_APP_TIMEZONE;
import Pagination from "../components/Pagination";
import Loading from "./Loading";

// todo:
// add loading screen
// add gear icon for settings for team
// -- add assignment
// -- add member
// -- add token member
// -- integrations page 
// -- probably need a data component with some state mgngmt
// -- single api call
// -- make home page better


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Team(args) {
  const user = useUser();
  const org_id = user?.organization;
  const navigate = useNavigate();
  const { team_id } = useParams();
  const user_is_admin = user.organization_role != 'member';
  const [loading, setLoading] = useState(false)
  const [team, setTeam] = useState({})
  const [membersLoading, setMembersLoading] = useState(false)

  const [issues, setIssues] = useState([])
  const [issuePageNumber, setIssuePageNumber] = useState(0)
  const [issueCount, setIssueCount] = useState(10)
  const [recentIssueCount, setRecentIssueCount] = useState(0)
  const [recentIssueResponseTime, setRecentIssueResponseTime] = useState(0)

  const [notifications, setNotifications] = useState([])
  const [notificationPageNumber, setNotificationPageNumber] = useState(0)
  const [notificationCount, setNotificationCount] = useState(10)
  const [recentNotificationCount, setRecentNotificationCount] = useState(0)

  const [assignments, setAssignments] = useState([])
  const [currentAssignments, setCurrentAssignments] = useState([])

  const stats = [
    { name: 'New Issues', value: recentIssueCount, change: '24hr', changeType: null },
    { name: 'New Notifications', value: recentNotificationCount, change: '24hr', changeType: 'positive' },
    { name: 'Response Time', value: recentIssueResponseTime, change: '24hr', changeType: null },
  ]

  const isCurrentAssignment = (assignment) => {
    return moment(assignment.start_date).isBefore(moment()) && moment(assignment.end_date).isAfter(moment())
  }

  const deleteAssignmentClicked = async (assignment_id) => {
    const response = await deleteAssignment({ token: user.token, user_id: user.id, assignment_id })
    if (!response) return;
    fetchTeamAssignments();
  }

  const fetchTeamIssues = async () => {
    setLoading(true)
    const response = await getTeamIssues({ token: user.token, user_id: user.id, org_id, team_id, page_number: issuePageNumber, count: issueCount })
    if (!response) return;
    setIssues([...response?.data])
    setLoading(false)
  }

  const fetchRecentIssuesCount = async () => {
    setLoading(true)
    const response = await getRecentTeamIssues({ token: user.token, user_id: user.id, team_id })
    if (!response) return;
    if (response.data.length) {
      const total = (response.data.map(i => i.date_created - i.acknowledged_at).reduce((a, b) => a + b, 0) / response.data.length)
      setRecentIssueResponseTime(total)
    } else {
      setRecentIssueResponseTime(0)
    }
    setRecentIssueCount(response.data.length)
    setLoading(false)
  }

  const fetchCurrentAssignments = async () => {
    setLoading(true)
    const response = await getTeamAssignments({ token: user.token, user_id: user.id, team_id })
    if (!response) return;
    const currentAssignments = [...response?.data]
      .filter(assignment => 
        moment(assignment.start_date).isBefore(moment()) && 
        moment(assignment.end_date).isAfter(moment()) &&
        assignment.order === 'primary'
      )
    setCurrentAssignments([...currentAssignments])
    setLoading(false)
  }

  useEffect(() => {
    fetchTeamIssues()
  }, [issuePageNumber])


  useEffect(() => {
    fetchTeamIssues()
    fetchCurrentAssignments()
    fetchRecentIssuesCount()
  }, [team_id])


  return (
    <>
      <div className="mx-auto max-w-7xl">
        <div className="px-4 pt-8 sm:px-6 lg:px-8">

          <div className="mt-8 flex flex-col">
            <h1 className="text-base font-semibold text-xl leading-6 text-gray-900">{team?.name}</h1>

            <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
              <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 ">
                {
                  // { name: 'Currently On Call', value: currentAssignments.map(a => a.user.name).join(','), change: null, changeType: null },
                  // { name: 'New Issues', value: recentIssueCount, change: '24hr', changeType: null },
                  // { name: 'New Notifications', value: recentNotificationCount, change: '24hr', changeType: 'positive' },
                  // { name: 'Response Time', value: recentIssueResponseTime, change: '24hr', changeType: null },


                }
                <div
                  className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-2 md:py-10 md:px-6 xl:px-8"
                >
                  <dt className="text-sm font-medium text-gray-500">Currently On Call</dt>
                  <dd
                    className={classNames(
                      'text-gray-700',
                      'text-xs font-medium'
                    )}
                  >
                    {null}
                  </dd>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {!!currentAssignments?.length && currentAssignments[0].user.name}
                    <p className="text-sm text-gray-500">
                      {!!currentAssignments?.length && moment(currentAssignments[0].start_date).format("MM/DD")} -
                      {!!currentAssignments?.length && moment(currentAssignments[0].end_date).format("MM/DD")}

                    </p>

                    {!currentAssignments?.length && 'Nobody 👎'}
                  </dd>
                </div>

                {stats.map((stat) => (
                  <div
                    key={stat.name}
                    className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-2 md:py-10 md:px-6 xl:px-8"
                  >
                    <dt className="text-sm font-medium  text-gray-500">{stat.name}</dt>
                    <dd
                      className={classNames(
                        stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                        'text-xs font-medium'
                      )}
                    >
                      {stat.change}
                    </dd>
                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
            <IssuesTable
              issues={issues}
              count={issueCount}
              setCount={setIssueCount}
              pageNumber={issuePageNumber}
              setPageNumber={setIssuePageNumber}
              defaultOpen={true}
              team_id={team_id}
              org_id={org_id}
            />
            <NotificationsTable
              count={notificationCount}
              setCount={setNotificationCount}
              pageNumber={notificationPageNumber}
              setPageNumber={setNotificationPageNumber}
              user={user}
              team_id={team_id}
              org_id={org_id}
              defaultOpen={false}
            />
            <AssignmentTable
              assignment_type={'Upcoming Assignments'}
              user_is_admin={user_is_admin}
              isCurrentAssignment={isCurrentAssignment}
              deleteAssignmentClicked={deleteAssignmentClicked}
              showCreateButton={true}
              createButtonLink={`/teams/${team_id}/assignments/new`}
              navigate={navigate}
              user={user}
              team_id={team_id}
              defaultOpen={false}
              order={'primary'}
            />
            <AssignmentTable
              assignment_type={'Secondaries'}
              user_is_admin={user_is_admin}
              isCurrentAssignment={isCurrentAssignment}
              deleteAssignmentClicked={deleteAssignmentClicked}
              showCreateButton={true}
              createButtonLink={`/teams/${team_id}/assignments/new`}
              navigate={navigate}
              user={user}
              team_id={team_id}
              defaultOpen={false}
              order={'secondary'}
            />

            <MembersTable
              user_is_admin={user_is_admin}
              showCreateButton={true}
              createButtonLink={`/teams/${team_id}/members/new`}
              navigate={navigate}
              defaultOpen={false}
              user={user}
              team_id={team_id}
              org_id={org_id}
            />
            <div>
              {user_is_admin && <button
                type="button"
                onClick={() => navigate(`/teams/${team_id}/tokens/new`)}
                className="m-8 mr-8 absolute right-0 inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
              >
                Create Token
              </button>}
            </div>


          </div>
        </div>
      </div>
    </>
  )
}

// assignments - add assignment
// issues
// notifications
// members - add member, - edit member, - remove member from org/team

function AssignmentTable({ assignment_type, user, team_id, defaultOpen, user_is_admin, isCurrentAssignment, deleteAssignmentClicked, navigate, showCreateButton = false, createButtonLink = null, order = null }) {
  const [showTable, setShowTable] = useState(defaultOpen)
  const [loading, setLoading] = useState(false)
  const [assignments, setAssignments] = useState(null)

  const fetchData = async () => {
    setLoading(true)
    const response = await getTeamAssignments({ token: user.token, user_id: user.id, team_id, order })
    if (!response) return;
    setAssignments([...response?.data].filter(assignment => !moment(assignment.end_date).isBefore(moment())))
    setLoading(false)
  }

  // Use effect to fetch data when the table is opened and data hasn't been fetched yet
  useEffect(() => {
    if (!showTable) return;
    fetchData();
  }, [showTable, team_id]);

  const toggleTable = () => {
    if (!showTable && !assignments) {
      fetchData();
    }
    setShowTable(!showTable);
  }
  return (
    <>
      <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
        <div className="p-4  overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="flex justify-between">
                <div className="flex">
                  <div
                    className="pr-4 hover:text-emerald-600"
                    onClick={() => toggleTable(!showTable)}
                  >
                    {!showTable
                      ? <PlusCircleIcon className="h-6 w-6" />
                      : <MinusCircleIcon className="h-6 w-6" />
                    }
                  </div>
                  <h1 className="text-base font-semibold leading-6 text-gray-900">{assignment_type}</h1>
                </div>
                {showCreateButton &&
                  <div>
                    {user_is_admin && <button
                      type="button"
                      onClick={() => navigate(createButtonLink)}
                      className=" inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                    >
                      +
                    </button>}
                  </div>
                }
              </div>
            </div>
            {showTable && <div>
              {loading && <Loading />}
              {!loading && !assignments?.length && <div>
                <h1 className="text-center ml-4 text-base font-semibold leading-6 text-gray-900"> No assignments here 🤔</h1>
              </div>
              }
              {!loading && !!assignments?.length && <div>
                <div className="overflow-hidden ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Start
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          End
                        </th>
                        {user_is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Delete
                        </th>}
                        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {assignments?.map((assignment, i) => (
                        <tr key={i} className={`${isCurrentAssignment(assignment) && 'bg-blue-200'}`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                            <Link className="text-emerald-600 hover:text-emerald-900" to={`/profile/${assignment.user._id}`}>
                              {assignment?.user?.name}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {moment(assignment?.start_date).tz(TIMEZONE).format('MM/DD/YYYY')}
                            {/* <span className="text-red-500">{bank?.auth_refresh_required ? ' - Authentication Refresh Required' : ''}</span> */}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment(assignment?.end_date).tz(TIMEZONE).format('MM/DD/YYYY')}
                          </td>
                          {user_is_admin && <td
                            className="cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-red-500 hover:text-red-900"
                            onClick={() => deleteAssignmentClicked(assignment._id)}
                          >
                            Delete
                          </td>}
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                          {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                        Delete
                      </p>
                    </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              }
            </div>
            }

          </div>
        </div>
      </div >
    </>
  )

}

function IssuesTable({ defaultOpen, issues, count, setCount, pageNumber, setPageNumber, team_id, org_id }) {
  const [showTable, setShowTable] = useState(defaultOpen)
  const [loading, setLoading] = useState(false)
  useEffect(() => { }, [team_id])
  return (
    <div className="mt-8 flex flex-col shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border border-gray-200 rounded-md">
      <div className="p-4 overflow-x-auto ">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="my-4">
            <div className="flex justify-between">
              <div className="flex">
                <div
                  className="pr-4 hover:text-emerald-600"
                  onClick={() => setShowTable(!showTable)}
                >
                  {!showTable
                    ? <PlusCircleIcon className="h-6 w-6" />
                    : <MinusCircleIcon className="h-6 w-6" />
                  }
                </div>
                <h1 className="text-base font-semibold leading-6 text-gray-900">Issues</h1>
              </div>
            </div>
          </div>
          {showTable && <div>
            {loading && <Loading />}
            {!loading && !issues?.length && <div>
              <h1 className="text-center ml-4 text-base font-semibold leading-6 text-gray-900"> No issues here 🤔</h1>
            </div>
            }
            {!loading && !!issues?.length && <div>
              < div className="overflow-hidden ">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Issue ID
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Source
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date Created
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Ack'd At
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Ack'd By
                      </th>
                      {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {issues?.map((issue, i) => (
                      <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:underline">
                          <Link className="text-emerald-600 hover:text-emerald-900" to={`/issues/${issue._id}`}>
                            {(issue?._id).slice(-4)}
                          </Link>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:underline">
                          <a href={issue.issue_url}>
                            {issue?.source.split('_').join(' ')}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {moment.utc(issue?.date_created).local().format('MM/DD/YY hh:mm:ss A')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {issue?.acknowledged ? moment.utc(issue?.acknowledged_at).local().format('MM/DD/YY hh:mm:ss A') : 'unacknowledged'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:underline">
                          {(!!issue?.acknowledged_by)
                            ? <Link className="text-emerald-600 hover:text-emerald-900" to={`/profile/${issue?.acknowledged_by._id}`}>{issue?.acknowledged_by?.name}</Link>
                            : 'N/A'
                          }
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex justify-end mr-2 mt-4">
                  <Pagination
                    count={count}
                    setCount={setCount}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>

              </div>
            </div>
            }
          </div>
          }
        </div>
      </div>
    </div >
  )
}

function MembersTable({ defaultOpen, user, team_id, org_id, user_is_admin, showCreateButton = true, createButtonLink = '', navigate }) {
  const [showTable, setShowTable] = useState(defaultOpen)
  const [loading, setLoading] = useState(false)
  const [members, setMembers] = useState(null)

  const fetchData = async () => {
    setLoading(true)
    const response = await getTeam({ token: user.token, user_id: user.id, org_id, team_id })
    if (!response) return;
    setMembers([...response.data.members])
    setLoading(false)
  }

  // Use effect to fetch data when the table is opened and data hasn't been fetched yet
  useEffect(() => {
    if (showTable) {
      fetchData();
    }
  }, [showTable]);

  const toggleTable = () => {
    if (!showTable) {
      fetchData();
    }
    setShowTable(!showTable);
  }

  return (
    <>
      <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
        <div className="p-4  overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="flex justify-between center">
                <div className="flex align-center">
                  <>
                    <div
                      className="pr-4 hover:text-emerald-600"
                      onClick={toggleTable}
                    >

                      {showTable
                        ? <MinusCircleIcon className="h-6 w-6" />
                        : <PlusCircleIcon className="h-6 w-6" />
                      }
                    </div>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Team Members</h1>
                  </>
                </div>
                {showCreateButton &&
                  <div>
                    {user_is_admin && <button
                      type="button"
                      onClick={() => navigate(createButtonLink)}
                      className=" inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                    >
                      +
                    </button>}
                  </div>
                }
              </div>
            </div>
            {showTable && <div>
              {loading && <Loading />}
              {!loading && !members?.length && <div>
                <h1 className="text-center ml-4 text-base font-semibold leading-6 text-gray-900"> No members here 🤔</h1>
              </div>}
              {(!loading && !!members?.length) && <div>
                <div className="overflow-hidden  ring-black ring-opacity-5 ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Email
                        </th>
                        {user_is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Role
                        </th>}
                        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {members?.map((member, i) => (
                        <tr key={i} >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-emerald-600 sm:pl-6 hover:text-emerald-900 ">
                            <Link to={`/profile/${member._id}`}>
                              {member?.name}
                            </Link>
                          </td>

                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {member?.email}
                            {/* <span className="text-red-500">{bank?.auth_refresh_required ? ' - Authentication Refresh Required' : ''}</span> */}
                          </td>
                          {user_is_admin && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{member.organization_role}</td>}
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                          {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                        Delete
                      </p>
                    </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              }
            </div>
            }
          </div>
        </div>
      </div >
    </>
  )

}

function NotificationsTable({ showCreateButton = false, defaultOpen, count, setCount, pageNumber, setPageNumber, team_id, org_id, user }) {
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState(null)

  const fetchData = async () => {
    setLoading(true)
    const response = await getTeamNotifications({ token: user.token, user_id: user.id, org_id, team_id, page_number: pageNumber, count })
    if (!response) return;
    setNotifications([...response.data])
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [pageNumber])

  useEffect(() => {
    if (!showTable) return;
    fetchData();
  }, [showTable, team_id])

  const toggleTable = () => {
    if (!showTable) {
      fetchData()
    }
    setShowTable(!showTable)
  }
  return (
    <div className="mt-8 flex flex-col shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border border-gray-200 rounded-md">
      <div className="p-4 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="my-4">
            <div className="flex justify-between">
              <div className="flex">
                <div
                  className="pr-4 hover:text-emerald-600"
                  onClick={toggleTable}
                >
                  {showTable
                    ? <MinusCircleIcon className="h-6 w-6" />
                    : <PlusCircleIcon className="h-6 w-6" />
                  }
                </div>
                <h1 className="text-base font-semibold leading-6 text-gray-900">Notifications</h1>
              </div>
              {showCreateButton &&
                <div>
                  {user_is_admin && <button
                    type="button"
                    onClick={() => navigate(createButtonLink)}
                    className=" inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                  >
                    +
                  </button>}
                </div>
              }
            </div>
          </div>
          {showTable && <div>
            {loading && <Loading />}
            {!loading && !notifications?.length && <div>
              <h1 className="text-center ml-4 text-base font-semibold leading-6 text-gray-900"> No notifications here 🤔</h1>
            </div>
            }
            {!loading && !!notifications?.length && <div>
              <div className="overflow-hidden ">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Issue ID
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Cell
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date Sent
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Time Sent
                      </th>
                      {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {notifications?.map((notification, i) => (
                      <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {(!!notification?.issue)
                            ? <Link className="text-emerald-600 hover:text-emerald-900" to={`/issues/${notification?.issue?._id}`}>
                              {(notification?.issue?._id).slice(-4)}
                            </Link>
                            : <>{(notification?.issue_id).slice(-4)}</>
                          }
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {notification?.user?.name}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {notification?.user?.cell}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {moment(notification?.date_created).format('MM/DD/YYYY')}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {moment.utc(notification?.date_created).local().format('hh:mm:ss A')}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex justify-end mr-2 mt-4">
                  <Pagination
                    count={count}
                    setCount={setCount}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            </div>
            }
          </div>
          }
        </div>
      </div>
    </div>
  )
}
